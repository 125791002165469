import {WebError, WebErrorType} from './../../utils/error';
import {ActivityBuilder} from './activities';
import {
  OdcLicense,
  GmpLicense,
  PoisonPermit,
  Location,
} from '@aglive/data-model/dist/misc/business';
import API from '../../config/api';
import {SPINNER_TOGGLE_ON, SPINNER_TOGGLE_OFF} from '../spinner/types';
import {AppThunk} from '../../store/types';
import {
  POST_USER_PROFILE_DATA,
  POST_BUSINESS_PROFILE_DATA,
  POST_PLUGIN_DATA,
  POST_REPORT_DATA,
  PolygonPaths,
  locationTypes,
  POST_API_KEY,
  POST_SCHEMA_DATA,
  POST_CONTACTS,
} from './types';
import axios from 'axios';
import {toggleModal} from '../modal/actions';
import {callAPI} from '../../utils/network';
import CONSTANT from '../../config/constant';
import { IMPORT_LOCATION, locationType } from '../location/types';
import { BusinessMisc, TokenService } from '@aglive/data-model';
import {UpdatedLinkedSite} from '../site/types';
import {createNewSite, modifySiteTokensPIC} from '../site/actions';

export type editUserProfileType = {
  name: string;
  mobileNumber?: string;
  emailAddress: string;
  // country: string;
};

export function getUserProfile(userId?: string): AppThunk<Promise<void>> {
  return async (dispatch) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});

      const response = await callAPI({
        url: API.POST.getTokenbyAddr,
        method: 'POST',
        data: {
          latestDetails: true,
          status: ['exist'],
          type: ['user'],
          activityType: [],
        },
      });

      const userProfile = response[0]?.details;
      dispatch({
        type: POST_USER_PROFILE_DATA,
        payload: userProfile,
      });
    } catch (e) {
      const error = e as WebErrorType;
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
        }),
      );
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}

export function getUserCeresTags(
  useOptiweigh: boolean,
  dayLimit?: number,
): AppThunk<Promise<any>> {
  return async (dispatch, getState) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});

      const plugins = getState().user.plugins ?? [];
      const response = await callAPI({
        url: API.POST.getTokenbyAddr,
        method: 'POST',
        data: {
          latestDetails: true,
          status: ['exist'],
          type: ['asset', 'angusCalve'],
          activityType: useOptiweigh
            ? ['addMovement', 'updateWeight', 'updateOptiWeigh']
            : ['addMovement', 'updateWeight'],
          latestActivityDateRange: dayLimit ?? 1,
          pluginType: plugins
            .filter((plg) => plg.status === 'activated')
            .map((plg) => plg.name),
        },
      });

      return Promise.resolve(response);
    } catch (e) {
      const error = e as WebErrorType;
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
        }),
      );
      return Promise.reject(error);
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}

export function getBusinessProfile(): AppThunk<Promise<any>> {
  return async (dispatch, getState) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});
      const businessId = getState().user.userProfileData.businessId;
      const businessResponse: Array<TokenService.BusinessToken> = await callAPI(
        {
          url: API.POST.getTokenbyExternalId,
          method: 'POST',
          data: {
            latestDetails: true,
            status: ['exist'],
            activityType: [],
            externalIds: [{agliveToken: businessId}],
          },
        },
      );

      dispatch({
        type: POST_BUSINESS_PROFILE_DATA,
        payload: businessResponse[0].details,
      });

      dispatch({
        type: POST_PLUGIN_DATA,
        payload: businessResponse[0].plugins ?? [],
      });

      dispatch({
        type: POST_REPORT_DATA,
        payload: businessResponse[0].reports ?? [],
      });
      dispatch({
        type: POST_SCHEMA_DATA,
        payload: businessResponse[0].schemas ?? {},
      });
      dispatch({
        type: POST_API_KEY,
        payload: businessResponse[0]['apiKeys'] ?? [],
      });
      dispatch({
        type: POST_CONTACTS,
        payload: businessResponse[0].contacts ?? [],
      });

      const locationArray = businessResponse[0].details.location ?? [];
      dispatch({
        type: IMPORT_LOCATION,
        payload: locationArray,
      });

      dispatch({ type: SPINNER_TOGGLE_OFF });
      return Promise.resolve(businessResponse[0]);
    } catch (e) {
      console.error(e.message);
      return Promise.reject(e);
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}

export function getProfiles(userId: string): AppThunk<Promise<void>> {
  return async (dispatch) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});

      // user profile
      const response = await callAPI({
        url: API.POST.getTokenbyAddr,
        method: 'POST',
        data: {
          latestDetails: true,
          status: ['exist'],
          type: ['user'],
          activityType: [],
        },
      });

      const userProfile = response[0]?.details;

      dispatch({
        type: POST_USER_PROFILE_DATA,
        payload: userProfile,
      });

      // business profile
      const businessResponse = await callAPI({
        url: API.POST.getTokenbyExternalId,
        method: 'POST',
        data: {
          latestDetails: true,
          status: ['exist'],
          activityType: [],
          externalIds: [{agliveToken: response[0]?.details.businessId}],
        },
      });

      dispatch({
        type: POST_BUSINESS_PROFILE_DATA,
        payload: businessResponse[0].details,
      });

      const locationArray = businessResponse[0].details.location ?? [];
      dispatch({
        type: IMPORT_LOCATION,
        payload: locationArray,
      });

      dispatch({
        type: POST_PLUGIN_DATA,
        payload: businessResponse[0].plugins ?? [],
      });

      dispatch({
        type: POST_REPORT_DATA,
        payload: businessResponse[0].reports ?? [],
      });
      dispatch({
        type: POST_SCHEMA_DATA,
        payload: businessResponse[0].schemas ?? {},
      });
      dispatch({
        type: POST_CONTACTS,
        payload: businessResponse[0].contacts ?? [],
      })

      dispatch({
        type: POST_API_KEY,
        payload: businessResponse[0]['apiKeys'] ?? [],
      });
    } catch (e) {
      const error = e as WebErrorType;
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
        }),
      );
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}

export function updateUserProfile(
  updatedUserData: editUserProfileType,
  userId: string,
  businessCountry: string,
): AppThunk<Promise<void>> {
  return async (dispatch) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});
      await callAPI({
        url: API.POST.createActivity,
        method: 'POST',
        data: {
          tokens: [
            {
              type: CONSTANT.ASSETTYPE.USER,
              externalIds: {userId},
              activities: [
                {
                  type: 'UP_details',
                  details: updatedUserData,
                },
              ],
            },
          ],
        },
      });

      dispatch(getProfiles(userId));
      dispatch(
        toggleModal({
          status: 'success',
          title: businessCountry === 'Argentina' ? 'Actualizado' : 'Updated',
          button: businessCountry === 'Argentina' ? 'Cerrar' : 'Close',
          CTAHandler: () => window.history.back(),
        }),
      );

      dispatch({type: SPINNER_TOGGLE_OFF});
    } catch (error) {
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
        }),
      );
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}

export function updateSignupUserProfile(
  updatedUserData: editUserProfileType,
  userId: string,
  invitationCode?: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});
      const state = getState();
      if (invitationCode) {
        const currUser = state.user.businessProfileData.users.find((u) => u.invitationCode === invitationCode)
        if (currUser.phone && !updatedUserData['mobileNumber']) {
          updatedUserData['mobileNumber'] = currUser.phone
        }
      }
      await callAPI({
        url: API.POST.createActivity,
        method: 'POST',
        data: {
          tokens: [
            {
              type: CONSTANT.ASSETTYPE.USER,
              externalIds: {userId},
              activities: [
                {
                  type: 'UP_details',
                  details: updatedUserData,
                },
              ],
            },
          ],
        },
      });

      dispatch({type: SPINNER_TOGGLE_OFF});
    } catch (error) {
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
        }),
      );
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}

type UpdateBusinessProfileProps = {
  isLivestock: boolean;
  updatedBusinessData: TokenService.BusinessToken['details'] & {
    isAmbioxera?: boolean;
    isWarakirri?: boolean;
  };
  deleteLocation: boolean;
  initialBusinessData: TokenService.BusinessToken['details'];
  deleteLicense: boolean;
  updatedLinkedSites: Array<UpdatedLinkedSite>;
  optiweigh?: BusinessMisc.AnimalBusinessInterface['optiweigh'] & {
    delete?: boolean;
  };
};

export function updateBusinessProfile({
  isLivestock,
  updatedBusinessData,
  deleteLocation,
  initialBusinessData,
  deleteLicense,
  updatedLinkedSites,
  optiweigh,
}: UpdateBusinessProfileProps): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});
      const {
        auth: {wallet},
        user: {userProfileData},
        site
      } = getState();
      // TODO: get user profile from state *
      // const agliveToken_business = user.details.businessId *
      // externalId: {agliveToken: agliveToken_business} *

      // Temp solution for the industries that don't have PICAddress,
      // use locationNickName as PICAddress for now
      if (!isLivestock) attachPICAddress(updatedBusinessData.location);

      /*const agliveBusinessProfile = {
        businessScope: updatedBusinessData.businessScope,
        businessType: updatedBusinessData.businessType,
        companyName: updatedBusinessData.companyName,
        companyNumber: updatedBusinessData.companyNumber,
        licenseNumber: updatedBusinessData.licenseNumber,
        licenseExpiryDate: updatedBusinessData.licenseExpiryDate,
        brandDescription: updatedBusinessData.brandDescription,
        imageUri: updatedBusinessData.imageUri,
        location: updatedBusinessData.location??[],
        permits:updatedBusinessData.permits??[],
      };*/

      const agliveBusinessProfile = getBusinessData(updatedBusinessData);

      const agliveToken_business = userProfileData.businessId;
      if (deleteLocation) {
        await callAPI({
          url: API.POST.createActivity,
          method: 'POST',
          data: {
            tokens: [
              {
                type: CONSTANT.ASSETTYPE.BUSINESS,
                externalIds: {agliveToken: agliveToken_business},
                activities: [
                  {
                    type: 'DEL_details',
                    details: {
                      location: initialBusinessData.location,
                    },
                  },
                ],
              },
            ],
          },
        });
      }
      let licenseActivities = [];
      // plant
      if (
        initialBusinessData.industryType === 'PLANTS' &&
        updatedBusinessData.industryType === 'PLANTS'
      ) {
        // licenses
        const initialLicenses = initialBusinessData.licenses ?? [];
        if (deleteLicense) {
          const deletedLicenses = initialLicenses.filter(
            (initialLicense) =>
              !includesLicense(initialLicense, updatedBusinessData.licenses),
          );
          const deletedLicensesId = deletedLicenses.map((license) =>
            license.name === 'Poison' ? license.permitId : license.licenseId,
          );
          if (deletedLicenses.length > 0) {
            licenseActivities.push(
              ActivityBuilder.deleteLicense(deletedLicensesId),
            );
          }
        }

        const addedLicenses = updatedBusinessData.licenses.filter(
          (updatedLicense) => !includesLicense(updatedLicense, initialLicenses),
        );
        const updatedLicenses = updatedBusinessData.licenses.filter(
          (updatedLicense) => includesLicense(updatedLicense, initialLicenses),
        );
        if (updatedLicenses.length > 0) {
          licenseActivities.push(
            ActivityBuilder.updateLicense(updatedLicenses),
          );
          updatedLicenses.forEach((license) => {
            if (license.name !== 'Poison') {
              const addedPermits = license.permits
                .filter((permit) => permit.permitId?.length <= 0)
                .map((permit) => {
                  const {permitId, ...rest} = permit;
                  return {...rest};
                });
              if (addedPermits.length > 0) {
                licenseActivities.push(
                  ActivityBuilder.addPermit(license.licenseId, addedPermits),
                );
              }
            }
          });
        }
        if (addedLicenses.length > 0) {
          licenseActivities.push(ActivityBuilder.addLicense(addedLicenses));
        }
      }
      const activities = [];

      // optiweight
      if (
        optiweigh &&
        optiweigh.delete &&
        updatedBusinessData.industryType === 'ANIMALS'
      ) {
        activities.push({
          type: 'DEL_details',
          details: {optiweigh: updatedBusinessData.optiweigh},
        });
        delete agliveBusinessProfile['optiweigh'];
      }

      //generate unique string so that we can differentiate establishment users
      agliveBusinessProfile.location = agliveBusinessProfile.location.map(
        (loc) => {
          if (!loc.locationUniqueId) {
            loc.locationUniqueId = Math.random().toString(36).slice(2);
          }
          return loc;
        },
      );
      delete agliveBusinessProfile['isAmbioxera'];
      delete agliveBusinessProfile['isWarakirri'];

      activities.push({
        type: 'UP_details',
        details: agliveBusinessProfile,
      });

      let picList = [];
      for (const [k, v] of Object.entries(updatedLinkedSites)) {
        //dairy only
        if (v.siteDetails.propertyType) {
          const matching = agliveBusinessProfile.location.find((loc) => loc.PICAddress === v.newPICAddress);
          if (matching['property_type'] !== v.siteDetails.propertyType) {
            updatedLinkedSites[k].siteDetails.propertyType = v.siteDetails.propertyType = matching['property_type'];
          }
          picList.push(`${v.newPICAddress}_${v.siteDetails.propertyType}`);
        }
      }
      await callAPI({
        url: API.POST.createActivity,
        method: 'POST',
        data: {
          tokens: [
            {
              type: CONSTANT.ASSETTYPE.BUSINESS,
              externalIds: {agliveToken: agliveToken_business},
              activities: [...licenseActivities, ...activities],
            },
          ],
        },
      });
      await modifySiteTokensPIC(updatedLinkedSites);
      
      site.forEach((s) => {
        picList.push(`${s.details.location}_${s.details.propertyType}`)
      });
      const newLoc = agliveBusinessProfile.location.filter((loc) => {
        if (!loc['property_type']) {
          return false;
        }
        return !picList.includes(`${loc.PICAddress}_${loc['property_type']}`)
      })
      if (newLoc.length) {
        for (let i = 0; i < newLoc.length; i++) {
          await createNewSite(newLoc[i].PICAddress, {
            name: newLoc[i].locationNickname,
            id: '',
            layerName: 'Site',
            children: [{
              name: '1',
              id: '1',
              layerName: '1',
              children: []
            }]
          }, newLoc[i]['region'], newLoc[i]['property_type'])
        }
      }

      dispatch({
        type: IMPORT_LOCATION,
        payload: agliveBusinessProfile.location,
      });

      if (optiweigh && optiweigh.apiKey) {
        await callAPI({
          url: API.POST.optiweigh,
          method: 'POST',
          data: {
            businessId: agliveToken_business,
            optiweighClientId: optiweigh.clientId,
            optiweighApiKey: optiweigh.apiKey,
            agliveApiKey: optiweigh.agliveKey,
            type: optiweigh.delete ? 'optiweighRemove' : 'optiweighRecords',
            scheduleRepetition: Number(optiweigh.interval),
          },
        });
      }

      dispatch(
        toggleModal({
          status: 'success',
          title:
            updatedBusinessData.businessCountry === 'Argentina'
              ? CONSTANT.ES_LABELS.updated
              : CONSTANT.EN_LABELS.updated,
          button:
            updatedBusinessData.businessCountry === 'Argentina'
              ? CONSTANT.ES_LABELS.close
              : CONSTANT.EN_LABELS.close,
          CTAHandler: () => window.history.back(),
        }),
      );
      dispatch({type: SPINNER_TOGGLE_OFF});
    } catch (error) {
      const e = error as WebErrorType;
      let errorMessage = e.message;
      if (e.code === 'E_DUPLICATE_FOUND') {
        errorMessage = `${e.error?.details}.`;
        if (e.message.toLowerCase().includes('duplicate found for license')) {
          const licenseNumbers = e.error?.source?.licenses
            ?.map((license) => license.licenseNumber ?? license.permitNumber)
            ?.join(', ');
          errorMessage = `${errorMessage} ${licenseNumbers}`;
        } else if (
          e.message.toLowerCase().includes('duplicate found for permit')
        ) {
          const permitNumbers = e.error?.source?.permits
            ?.map((permit) => permit.permitNumber)
            ?.join(', ');
          errorMessage = `${errorMessage} ${permitNumbers}`;
        }
      }
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: errorMessage,
        }),
      );
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}

const includesLicense = (
  target: OdcLicense | GmpLicense | PoisonPermit,
  licenses: (OdcLicense | GmpLicense | PoisonPermit)[],
) => {
  const index = licenses?.findIndex((license) => {
    if (license.name === 'Poison' && target.name === 'Poison') {
      return license.permitId === target.permitId;
    } else if (
      (license.name === 'ODC' && target.name === 'ODC') ||
      (license.name === 'GMP' && target.name === 'GMP')
    ) {
      return license.licenseId === target.licenseId;
    }
    return undefined;
  });
  return index !== -1;
};

const getBusinessData = (
  businessData: TokenService.BusinessToken['details'] & {
    isAmbioxera?: boolean;
    isWarakirri?: boolean;
  },
) => {
  if (businessData.industryType === 'PLANTS') {
    const {users, industryType, licenses, ...rest} = businessData;
    return rest;
  }
  const {users, industryType, ...rest} = businessData;
  return rest;
};

function attachPICAddress(locationData: Array<locationType>) {
  locationData?.forEach((location) => {
    location.PICAddress = location.locationNickname;
  });
}

type CredentialError = {
  error: {
    Errors: any[];
    error_description: string;
  };
  type: string;
};

export async function validateCredential(
  // validateLocation: Array<locationTypes>,
  nlisAccounts: {[key: string]: string},
  dispatch,
) {
  const locationError = [];
  const locationStatus = [];
  dispatch({type: SPINNER_TOGGLE_ON});
  const accounts = Object.keys(nlisAccounts)
  for (let i = 0; i < accounts?.length; i++) {
    // const credential: {lpa?: object; nlis?: object; pic: string} = {pic: ''};
    // credential.pic = validateLocation[i].PICAddress;

    // if (validateLocation[i].LPAUserID && validateLocation[i].LPAPassword) {
    //   console.log('got password??', validateLocation[i])
    //   credential.lpa = {
    //     scope: 'lpa_scope',
    //     username: `${credential.pic}-${validateLocation[i].LPAUserID}`, // "QCZZ8888-1201067"
    //     password: validateLocation[i].LPAPassword, // "1201067"
    //   };
    // }

    // if (validateLocation[i].NLISUserID) {
    //   credential.nlis = {
    //     scope: 'nlis_scope',
    //     username: validateLocation[i].NLISUserID, // "2PRODAL2"
    //     password: validateLocation[i].NLISPassword, // "2PRODAL2"
    //   };
    // }
    // Only proceed to validate the credentials and PIC when either one or both of the credentials are provided
    if (!!nlisAccounts[accounts[i]]) {
      try {
        const response = await axios.post(API.POST.validate, {
          nlis: {
            scope: 'nlis_scope',
            username: accounts[i],
            password: nlisAccounts[accounts[i]]
          }
        });
        locationStatus.push(response?.statusText);
      } catch (error) {
        const transformedErrors: string[] =
          error?.response?.data?.source?.errors.map(
            (credentialError: CredentialError) => {
              if (credentialError?.error?.Errors) {
                return credentialError.error.Errors.map(
                  (err: {Message: string}) => {
                    if (err.Message?.match(/pic.*not exist.*database/i)) {
                      return 'PIC does not exist in NLIS database';
                    }
                    return err.Message;
                  },
                ).join(', ');
              } else if (credentialError.error?.error_description) {
                const errSpaced = credentialError.error.error_description
                  .split('_')
                  .join(' ');
                if (errSpaced.match(/invalid.*username.*password/i)) {
                  return `Invalid ${credentialError.type?.toUpperCase()} username or password`;
                }
                return errSpaced;
              }
              return credentialError.type;
            },
          );

        let errorObject = {
          details: transformedErrors,
          index: accounts[i],
        };
        locationError.push(errorObject);
        dispatch({type: SPINNER_TOGGLE_OFF});
      }
    }
  }
  if (locationError?.length >= 1) {
    throw locationError;
  } else {
    dispatch({type: SPINNER_TOGGLE_OFF});
    return locationStatus;
  }
}

export function createGeofence(
  polygonPaths: PolygonPaths,
  locationIndex: number,
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    try {
      if (!polygonPaths) {
        console.log('INVALID_INPUT');
        throw new WebError('INVALID_INPUT');
      }

      const businessId = getState().user.userProfileData.businessId;
      const businessProfile = getState().user.businessProfileData;
      let location = JSON.parse(
        JSON.stringify(getState().user.businessProfileData.location),
      );
      const newLocationArr = getLocationArray(
        location[locationIndex],
        locationIndex,
        polygonPaths,
      );
      let activities = [];
      dispatch({type: SPINNER_TOGGLE_ON});

      if (location[locationIndex].geofence?.length > polygonPaths.length) {
        //delete first if previous array is larger
        let delLocation = [];
        delLocation[locationIndex] = {
          geofence: location[locationIndex].geofence,
        };
        activities.push({
          type: 'DEL_details',
          details: {
            location: delLocation,
          },
        });
      }

      if (polygonPaths.length > 0) {
        location[locationIndex].geofence = polygonPaths;
        activities.push({
          type: 'UP_details',
          details: {
            location: newLocationArr,
          },
        });
      }

      await callAPI({
        url: API.POST.createActivity,
        method: 'POST',
        data: {
          tokens: [
            {
              type: CONSTANT.ASSETTYPE.BUSINESS,
              externalIds: {
                agliveToken: businessId,
              },
              activities: activities,
            },
          ],
        },
      });

      dispatch(
        toggleModal({
          status: 'success',
          title: 'Saved',
        }),
      );
      dispatch({
        type: POST_BUSINESS_PROFILE_DATA,
        payload: {...businessProfile, location: location},
      });
    } catch (e) {
      const error = e as WebErrorType;
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
        }),
      );
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}

function getLocationArray(
  location: locationTypes,
  locationIndex: number,
  polygonPaths: PolygonPaths,
) {
  if (locationIndex === 0) {
    return [{...location, geofence: polygonPaths}];
  } else {
    const locArr = [];
    let i = locationIndex;
    while (i > 0) {
      locArr.push({});
      i--;
    }
    locArr.push({...location, geofence: polygonPaths});
    return locArr;
  }
}

export function sendAlertSetting(
  businessId: string,
  pic: string,
  alertSettings: {
    noActivity: boolean;
    lowActivity: boolean;
    mediumActivity: boolean;
    highActivity: boolean;
    extremelyHighActivity: boolean;
    location: boolean;
    inactive: boolean;
  },
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});
      const locationList: Array<locationTypes> =
        getState().user.businessProfileData.location;
      const alertSetting = {
        activityLevel: {
          no: alertSettings.noActivity,
          low: alertSettings.lowActivity,
          medium: alertSettings.mediumActivity,
          high: alertSettings.highActivity,
          extreme: alertSettings.extremelyHighActivity,
        },
        location: alertSettings.location,
        inactive: alertSettings.inactive,
      };
      const updateLocation = locationList.map((location) => {
        if (location.PICAddress === pic) {
          return {...location, tagAlertStandard: alertSetting};
        } else {
          return location;
        }
      });
      await callAPI({
        url: API.POST.createActivity,
        method: 'POST',
        data: {
          tokens: [
            {
              type: CONSTANT.ASSETTYPE.BUSINESS,
              externalIds: {agliveToken: businessId},
              activities: [
                {
                  type: 'UP_details',
                  details: {
                    location: updateLocation,
                  },
                },
              ],
            },
          ],
        },
      });
      dispatch(getBusinessProfile()); //update to latest
    } catch (error) {
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
        }),
      );
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}

export function getPlugins(uuid?: string): AppThunk<Promise<any>> {
  return async (dispatch) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});

      const response = await callAPI({
        url: API.GET.plugins + (uuid ? `?pluginId=${uuid}` : ''),
        method: 'GET',
      });
      return Promise.resolve(response);
    } catch (e) {
      const error = e as WebErrorType;
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
        }),
      );
      return Promise.reject(error);
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}

export function activatePlugin(
  activityType: 'activatePlugin' | 'deactivatePlugin' | 'editPlugin',
  details: {
    uuid: string;
    credentials?: any;
    schedule?: string;
    status?: TokenService.BusinessPlugin['status'];
  },
  successCallback?: () => void,
  failCallback?: () => void,
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});
      await callAPI({
        url: API.POST.createActivity,
        method: 'POST',
        data: {
          tokens: [
            {
              type: CONSTANT.ASSETTYPE.BUSINESS,
              externalIds: {
                agliveToken: getState().user.userProfileData.businessId,
              },
              activities: [
                {
                  type: activityType,
                  details: details,
                },
              ],
            },
          ],
        },
      });
      dispatch(
        toggleModal({
          status: 'success',
          title:
            activityType === 'editPlugin'
              ? 'Updated'
              : activityType === 'activatePlugin'
              ? 'Activated'
              : 'Deactivated',
          CTAHandler: successCallback,
        }),
      );

      dispatch({type: SPINNER_TOGGLE_OFF});
    } catch (error) {
      console.log('error', error, failCallback);
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
          CTAHandler: failCallback,
        }),
      );
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}

export function canDeleteLocation(
  picAddress: string,
): AppThunk<Promise<{canDelete: boolean; reason: 'linked_site' | ''}>> {
  return async (dispatch, getState) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});

      let sites = getState().site;
      // cannot delete location with linked site
      if (!sites?.length) {
        sites = await callAPI({
          url: API.POST.getTokenbyAddr,
          method: 'POST',
          data: {
            latestDetails: true,
            status: ['exist'],
            type: [CONSTANT.ASSETTYPE.SITE],
            activityType: [],
          },
        });
      }
      const hasLinkedSite = sites.find(
        (site) => site.details.location === picAddress,
      );
      if (hasLinkedSite) {
        return {
          canDelete: false,
          reason: 'linked_site',
        };
      }

      // safe to delete
      return {
        canDelete: true,
        reason: '',
      };
    } catch (e) {
      const error = e as WebErrorType;
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
        }),
      );
      return Promise.reject(error);
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}

export function updateMLABusiness(code: string): AppThunk<Promise<void>> {
  return async (dispatch) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});
      
      await callAPI({
        url: API.POST.mlaDetails,
        method: 'POST',
        data: {
          code: code
        },
      });

      // dispatch(
      //   toggleModal({
      //     status: 'success',
      //     title:
      //       updatedBusinessData.businessCountry === 'Argentina'
      //         ? CONSTANT.ES_LABELS.updated
      //         : CONSTANT.EN_LABELS.updated,
      //     button:
      //       updatedBusinessData.businessCountry === 'Argentina'
      //         ? CONSTANT.ES_LABELS.close
      //         : CONSTANT.EN_LABELS.close,
      //     CTAHandler: () => window.history.back(),
      //   }),
      // );
      dispatch({type: SPINNER_TOGGLE_OFF});
      dispatch(getBusinessProfile())
    } catch (error) {
      const e = error as WebErrorType;
      let errorMessage = e.message;
      console.log(errorMessage)
      // dispatch(
      //   toggleModal({
      //     status: 'failed',
      //     title: error.title,
      //     subtitle: errorMessage,
      //   }),
      // );
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}
