export const ENV = process.env.REACT_APP_DEPLOYMENT_ENV;
export const VERSION = '1.6.1';
const HTTP = 'http';
const HTTPS = 'https';

export const tokenService = process.env.REACT_APP_API_URL;
export const envdService = process.env.REACT_APP_API_URL;
export const csvService = process.env.REACT_APP_API_URL;
export const walletConnectURL = process.env.REACT_APP_WALLETCONNECT_URL;
export const blockchainService = `${HTTP}://52.62.73.61:8500`;
export const blockchainContractAddr =
  '0x42A44d4024bD016E5AdEBb2A9277D19f930d261b';

// ENVD Credentials
export const AUTH_TOKEN =
  'ATYJ+cFIWzhQPyPJix7AM/epyaU+XPR6UVyBV7StRYCyk8uPHqj8GF1PdPm304yTKl6hrg9mn1GA1LTjRNSNnQ==';

export const GOOGLE_RECAPTCHA_SITE_KEY = process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY